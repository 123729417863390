import React from "react"
import { Link } from "gatsby"
import { useLocation, useMatch  } from "@reach/router"
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Chip from "@material-ui/core/Chip"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Typography from "@material-ui/core/Typography"
import AddIcon from "@material-ui/icons/Add"

const primary = "#26a27b"
const dark = "#3a866e"

const useStyles = makeStyles((theme) => ({
  menuFix: {
    "&:hover > a": {
      color: "inherit",
    },
  },
}))

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: "transparent",
    fontSize: ".8rem",
    height: theme.spacing(3),
    fontWeight: theme.typography.fontWeightRegular,
    "&:hover, &:focus": {
      backgroundColor: "transparent",
    },
    "&:active": {
      boxShadow: theme.shadows[1],
      backgroundColor: "transparent",
    },
    "& > span:first-child": {
      color: primary,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: ".7rem",
    },
  },
}))(Chip)

const DirectoryPages = [
  {
    id: 0,
    text: "All Companies",
    url: "/directory/",
  },
  {
    id: 1,
    text: "Michigan Companies",
    url: "/directory/michigan/top-web-design-companies/",
  },
  {
    id: 2,
    text: "Grand Rapids Companies",
    url: "/directory/grand-rapids/top-web-design-companies/",
  },
  {
    id: 3,
    text: "Ann Arbor Companies",
    url: "/directory/ann-arbor/top-web-design-companies/",
  },
]

export default function DirectoryBreadcrumbs() {
  const theme = useTheme()
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [breadcrumbTitle, setBreadcrumbTitle] = React.useState(null)
  const anchorRef = React.useRef(null)
  const matchesXS = useMediaQuery(theme.breakpoints.down("xs"))
  const { pathname } = useLocation()

  const routerMatch = useMatch("/directory/*")
  const uriLength = routerMatch["*"].toString().length

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }

    setOpen(false)
  }
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }

  const prevOpen = React.useRef(open)
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus()
    }

    if (pathname.includes("/directory/ann-arbor")) {
      setBreadcrumbTitle("Ann Arbor")
    }
    if (pathname.includes("/directory/michigan")) {
      setBreadcrumbTitle("Michigan")
    }
    if (pathname.includes("/directory/grand-rapids")) {
      setBreadcrumbTitle("Grand Rapids")
    }

    prevOpen.current = open
  }, [open, pathname])

  return (
    <>
      <Breadcrumbs
        aria-label="breadcrumb"
        style={{
          paddingTop: "1em",
          marginLeft: "1em",
          fontSize: matchesXS ? ".7rem" : ".8rem",
        }}
      >
        <Link color="inherit" component={Link} to="/">
          Home
        </Link>
        <StyledBreadcrumb
          label="Top Web Design Companies"
          deleteIcon={<ExpandMoreIcon />}
          onDelete={handleToggle}
          ref={anchorRef}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        />

        {uriLength > 0 && (
          <Typography
            color="textPrimary"
            style={{ fontSize: matchesXS ? ".7rem" : ".8rem" }}
          >
            {breadcrumbTitle}
          </Typography>
        )}
      </Breadcrumbs>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                  className={classes.menuFix}
                >
                  {DirectoryPages.filter((page) => page.url !== pathname).map(
                    (page) => (
                      <MenuItem
                        key={page.id}
                        component={Link}
                        style={{ fontSize: ".9rem" }}
                        to={page.url}
                      >
                        {page.text}
                      </MenuItem>
                    )
                  )}
                  <MenuItem
                    component={Link}
                    style={{
                      fontSize: ".9rem",
                      backgroundColor: dark,
                      color: "#fff",
                    }}
                    to="/directory/submit/"
                  >
                    <AddIcon style={{ marginLeft: "-.25rem" }} /> Add Company
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  )
}
