import React from "react"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import ButtonArrow from "./ButtonArrow"
import revolutionBackground from "../assets/images/repeatingBackground.svg"

const dark = "#1c8460"
const primary = "#26a27b"

const useStyles = makeStyles((theme) => ({
  revolutionBackground: {
    backgroundImage: `url(${revolutionBackground})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "100%",
    width: "100%",
  },
  portfolioCard: {
    position: "absolute",
    boxShadow: theme.shadows[10],
    borderRadius: 15,
    padding: "10em",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "8em",
      paddingBottom: "8em",
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 0,
      width: "100%",
    },
  },
  smallPortfolioCard: {
    position: "absolute",
    boxShadow: theme.shadows[10],
    borderRadius: 15,
    padding: "5em",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "4em",
      paddingBottom: "4em",
      paddingLeft: 0,
      paddingRight: 0,
      borderRadius: 0,
      width: "100%",
    },
  },
  learnButtonHero: {
    borderColor: dark,
    color: dark,
    borderWidth: 2,
    textTransform: "none",
    borderRadius: 50,
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: "0.9rem",
    height: 45,
    width: 165,
  },
}))

export default function PortfolioCTA({ small = null }) {
  const classes = useStyles()

  return (
    <>
      <Grid item xs={12}>
        <Grid item>
          {/*----- Portfolio CTA Block -----*/}
          <Grid
            container
            style={{
              height: small ? "50em" : "100em",
              marginTop: small ? "2em" : "12em",
            }}
            alignItems="center"
            justify="center"
          >
            <Card
              className={
                small ? classes.smallPortfolioCard : classes.portfolioCard
              }
            >
              <CardContent>
                <Grid
                  container
                  direction="column"
                  style={{ textAlign: "center" }}
                >
                  <Grid item>
                    <Typography
                      variant="h3"
                      gutterBottom
                      style={{ color: `${dark}` }}
                    >
                      Our Portfolio
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      style={{ marginBottom: "1rem" }}
                    >
                      We don't just want to tell you about our cutting-edge
                      websites. <br /> We want to show you. View our web design
                      portfolio now!
                    </Typography>
                    <Button
                      component={Link}
                      to="/portfolio/"
                      variant="outlined"
                      className={classes.learnButtonHero}
                    >
                      <span style={{ marginRight: 10 }}>Our Websites</span>
                      <ButtonArrow width={15} height={15} fill={`${primary}`} />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <div className={classes.revolutionBackground} />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
